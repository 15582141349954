import React, { FC, useState } from 'react';
import { Button, Form, Grid, Message } from 'semantic-ui-react';

import Navbar from 'components/Navbar';

import theme from './theme.scss';

const LoginForm: FC<{
  onSubmit: (email: string, password: string) => void;
  loading: boolean;
  error?: {};
}> = props => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const { onSubmit, loading, error } = props;

  return (
    <div>
      <Navbar size={'thin'} />
      <Grid className={theme.loginForm} textAlign="center" style={{ height: '100vh' }}>
        <Grid.Column widescreen={3} largeScreen={4} computer={6} tablet={7} mobile={14}>
          <div className={theme.formContainer}>
            <h1>Log In</h1>
            <Form size="large" error={!!error}>
              <Form.Input
                fluid
                placeholder="Phone Number"
                onChange={e => setUsername(e.target.value)}
              />
              <Form.Input
                fluid
                placeholder="Password"
                type="password"
                onChange={e => setPassword(e.target.value)}
              />
              <Message error>
                <div>
                  <b>{error}</b>
                </div>
              </Message>
              <Button
                color="teal"
                basic
                fluid
                size="large"
                loading={loading}
                onClick={() => onSubmit(username, password)}
              >
                Login
              </Button>
            </Form>
          </div>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default LoginForm;
