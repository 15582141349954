import React, { FC } from 'react';
import { useMutation } from 'react-apollo';
import { Divider, Loader } from 'semantic-ui-react';
import classNames from 'classnames';

import AdminLayout from 'layouts/AdminLayout';
import Title from 'components/Title';

import RESET_PASSWORD_INITIATE from 'graphql/mutations/resetPasswordInitiate.graphql';

import theme from './theme.scss';

const Link: FC = () => {
  const [resetPasswordInitiate, { loading, error, data }] = useMutation(RESET_PASSWORD_INITIATE);

  if (loading) {
    return (
      <div className={theme.link}>
        <Loader className={theme.loader} active={loading} inline size="mini" />
      </div>
    );
  }

  if (error) {
    return (
      <div className={theme.link}>
        <p className={theme.name}>There was an error processing this request.</p>
      </div>
    );
  }

  if (data) {
    return (
      <div className={theme.link}>
        <p className={theme.success}>Reset password link sent to your email.</p>
      </div>
    );
  }

  return (
    <div onClick={() => resetPasswordInitiate()} className={classNames(theme.link, theme.clickable)}>
      <p className={theme.name}>Reset Password <Loader className={theme.loader} active={loading} inline size="mini" /></p>
    </div>
  );
};

const Settings: FC = () => {
  return (
    <AdminLayout>
      <div className={theme.settings}>
        <Title name="Settings" backButtonRoute="/" />
        <Divider />
        <div className={theme.linksContainer}>
        <Link />
        </div>
      </div>
    </AdminLayout>
  );
};

export default Settings;
