import React, { FC } from 'react';
import classNames from 'classnames';
import moment from 'moment';

import { getStatus } from 'utils/getStatus';

import theme from './theme.scss';

type OrderListProps = {
  orders: [
    {
      id: string;
      referenceId: string;
      status: string;
      createdAt: string;
      orderedBy: {
        fullName: string;
      };
      dishCount: number;
    }
  ];
  selectedOrder: string;
  onOrderSelect: (orderId: string) => void;
};

const OrderList: FC<OrderListProps> = ({ orders, selectedOrder, onOrderSelect }) => {
  return (
    <div className={theme.orderList}>
      {orders
        .filter(o => o.status !== 'CREATED' && o.status !== 'CANCELLED')
        .map(order => (
          <div
            className={classNames(
              theme.orderItem,
              theme[order.status],
              selectedOrder === order.id ? theme.selected : null
            )}
            onClick={() => onOrderSelect(order.id)}
            key={order.id}
          >
            <p className={theme.label}>{getStatus(order.status)}</p>
            <div className={theme.content}>
              <div className={theme.arrivalTime}>{moment(order.createdAt).fromNow(true)}</div>
              <div className={theme.information}>
                <p className={theme.orderedBy}>{order.orderedBy ? order.orderedBy.fullName : ''}</p>
                <p className={theme.referenceId}>#{order.referenceId}</p>
              </div>
              <div className={theme.items}>
                {order.dishCount === 1 ? '1 ITEM' : `${order.dishCount} ITEMS`}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default OrderList;
