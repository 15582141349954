import renderRoutes from 'utils/renderRoutes';
import App from 'components/App';
import { isAuthenticated } from 'utils/auth';
import dashboard from './dashboard/route';
import settings from './settings/route';
import foodcourt from './foodcourt/route';
import vendor from './vendor/route';
import rooms from './rooms/route';
import orders from './orders/route';
import ordersummary from './ordersummary/route';
import orderQRCode from './orderqrcode/route';
import vendorConsole from './vendorconsole/route';
import notFound from './default/route';
import login from './login/route';

const routeConfigs = {
  redirects: [
    {
      path: '/',
      to: '/dashboard',
      exact: true,
    },
    {
      path: '/login',
      to: '/',
      exact: true,
      condition: isAuthenticated,
    },
  ],
  component: App,
  routes: [
    dashboard,
    settings,
    foodcourt,
    vendor,
    ordersummary,
    orderQRCode,
    rooms,
    orders,
    vendorConsole,
    login,
    notFound,
  ],
};

export default () => {
  return renderRoutes(routeConfigs);
};
