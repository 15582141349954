import React, { FC } from 'react';
import { useMutation } from 'react-apollo';
import { Checkbox, Loader } from 'semantic-ui-react';

import SWITCH_VENDOR_AVAILABILITY from 'graphql/mutations/switchVendorAvailability.graphql';

import theme from './theme.scss';

type OpenCloseVendorSwitchProps = {
  vendorId: string;
  status: string;
  canSwitchVendorAvailability: boolean;
};

const OpenCloseVendorSwitch: FC<OpenCloseVendorSwitchProps> = ({
  vendorId,
  status,
  canSwitchVendorAvailability,
}) => {
  const [switchAvailability, { loading }] = useMutation(SWITCH_VENDOR_AVAILABILITY);

  function onSwitch() {
    switchAvailability({
      variables: {
        vendorId,
        isOpen: status !== 'OPEN',
      },
    });
  }

  function vendorNotInOpenCloseState(): boolean {
    // Allow only switches between OPEN and CLOSED states. Inactive if in any other.
    return !(status === 'OPEN' || status === 'CLOSED');
  }

  return (
    <React.Fragment>
      <p className={theme.vendorStatus}>This vendor is now <span>{status}</span>.</p>
    <div className={theme.switchAvailability}>
      <Checkbox
        toggle
        checked={status === 'OPEN'}
        onChange={onSwitch}
        disabled={!canSwitchVendorAvailability || vendorNotInOpenCloseState()}
      />
      <Loader active={loading} inline size="mini" />
    </div>
    </React.Fragment>
  );
};

export default OpenCloseVendorSwitch;
