import React, { FC } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';

import Navbar from 'components/Navbar';

import theme from './theme.scss';

const AdminLayout: FC = props => {
  return (
    <div className={theme.adminLayout}>
      <Navbar />
      <div className={theme.content}>
        <Grid className={theme.grid}>
          <Grid.Row centered>
            <Grid.Column
              className={theme.gridColumn}
              widescreen={7}
              largeScreen={8}
              computer={12}
              tablet={14}
              mobile={15}
            >
              {props.children}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
};

export default withRouter(AdminLayout);
