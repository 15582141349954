// @ts-nocheck
import React, { FC, useState } from 'react';
import { useMutation } from 'react-apollo';
import { Button, Message, Select } from 'semantic-ui-react';
import moment from 'moment';

import ModalWithSidePanel from 'components/ModalWithSidePanel';
import GENERATE_REPORT from 'graphql/mutations/generateSettlementReport.graphql';

import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import theme from './theme.scss';

type VendorSettlementReportProps = {
  settlementReport: {
    vendorId: string;
    vendorName: string;
    fromDate?: Date;
    toDate?: Date;
  };
  onClose: () => void;
};

const typeOptions = [
  { key: 'ax', value: 'VENDOR_TRANSACTION_SUMMARY', text: 'Vendor Transaction Summary' },
  { key: 'aq', value: 'VENDOR_TAX_DETAILED_REPORT', text: 'Vendor Tax Report' },
  { key: 'al', value: 'VENDOR_NIGHT_AUDIT_REPORT', text: 'Vendor Night Audit Report' },
  { key: 'dz', value: 'DISH_REPORT', text: 'Dish Report' },
  { key: 'as', value: 'ORDER_DISHES_REPORT', text: 'Order Dishes Report' },
];

const GenerateSettlementReport: FC<VendorSettlementReportProps> = ({
  settlementReport,
  onClose,
}) => {
  const [generateReport, { loading, error }] = useMutation(GENERATE_REPORT);

  const [fromDate, setFromDate] = useState<Date>(settlementReport.fromDate || new Date());

  const [toDate, setToDate] = useState<Date>(settlementReport.toDate || new Date());

  const [type, setType] = useState('VENDOR_TRANSACTION_SUMMARY');

  function onSubmit() {
    generateReport({
      variables: {
        vendorId: settlementReport.vendorId,
        fromDate: moment(fromDate).format('YYYY-MM-DD'),
        toDate: moment(toDate).format('YYYY-MM-DD'),
        reportType: type,
      },
    }).then(({ data }: any) => {
      if (data.generateVendorReport && data.generateVendorReport.fileLocation)
        window.open(data.generateVendorReport.fileLocation);
    });
  }

  return (
    <div className={theme.information}>
      <h3>Generate Settlement Report</h3>
      <div className={theme.field}>
        <p className={theme.label}>VENDOR NAME</p>
        <p className={theme.value}>{settlementReport.vendorName}</p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>TYPE</p>
        <Select
          placeholder="Select Type"
          value={type}
          onChange={(e, d) => setType(d.value)}
          options={typeOptions}
        />
      </div>
      <div className={theme.field}>
        <p className={theme.label}>FROM DATE</p>
        <DatePicker
          selected={fromDate}
          onChange={value => setFromDate(value)}
          dateFormat="yyyy-MM-dd"
        />
      </div>
      <div className={theme.field}>
        <p className={theme.label}>TO DATE</p>
        <DatePicker
          selected={toDate}
          onChange={value => setToDate(value)}
          dateFormat="yyyy-MM-dd"
        />
      </div>
      {error ? <Message error header="An error occured. Please try again later." /> : null}
      <Button primary loading={loading} onClick={() => onSubmit()}>
        Submit
      </Button>
    </div>
  );
};

const VendorSettlementReportModal: FC<{
  vendorId: string;
  vendorName: string;
  onClose: () => void;
}> = ({ vendorId, vendorName, onClose }) => {
  const data = { vendorId, vendorName };
  const sections = data
    ? [
        {
          key: 'report',
          name: 'Settlement Report',
          emoji: '📋',
          component: <GenerateSettlementReport settlementReport={data} onClose={onClose} />,
        },
      ]
    : [];
  return (
    <ModalWithSidePanel
      name="REPORT GENERATION"
      loading={false}
      sections={sections}
      onClose={onClose}
    />
  );
};

export default VendorSettlementReportModal;
