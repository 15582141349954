function getStatus(status) {
  switch (status) {
    case 'CREATED':
      return 'PENDING';
    case 'PAID':
      return 'NEW';
    case 'INPROGRESS':
      return 'PREPARING';
    case 'READY':
      return 'READY';
    case 'DELIVERED':
      return 'DELIVERED';
    case 'CANCELLED':
      return 'CANCELLED';
    case 'BILLED':
      return 'BILLED TO ROOM'; 
    default:
      return '';
  }
}

function getFullStatus(status: string) {
  switch (status) {
    case 'CREATED':
      return 'PAYMENT PENDING';
    case 'PAID':
      return 'NEW ORDER';
    case 'INPROGRESS':
      return 'PREPARING FOOD';
    case 'READY':
      return 'READY FOR PICKUP';
    case 'DELIVERED':
      return 'DELIVERED';
    case 'CANCELLED':
      return 'CANCELLED';
    case 'BILLED':
      return 'BILLED TO ROOM';  
    default:
      return '';
  }
}

function getRoomStatus(status: string) {
  switch (status) {
    case 'OUT_OF_ORDER':
      return 'OUT_OF_ORDER';
    case 'VACANT':
      return 'VACANT';
    case 'OCCUPIED':
      return 'OCCUPIED';
    case 'UPCOMING':
      return 'UPCOMING';
    default:
      return '';
  }
}

export { getStatus, getFullStatus, getRoomStatus };
