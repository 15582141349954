// @ts-nocheck
import React, { FC, useState } from 'react';
import { useMutation } from 'react-apollo';
import { Button, Message, Select } from 'semantic-ui-react';
import moment from 'moment';

import ModalWithSidePanel from 'components/ModalWithSidePanel';
import GENERATE_REPORT from 'graphql/mutations/generateFoodCourtTransactionReport.graphql';

import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import theme from './theme.scss';

const typeOptions = [
  // { key: 'af', value: 'FOOD_TRANSACTION_SUMMARY', text: 'Food Court Transaction Summary' },
  // { key: 'ax', value: 'VENDOR_TRANSACTION_SUMMARY', text: 'Vendor Transaction Summary' },
  // { key: 'al', value: 'VENDOR_GST_REPORT', text: 'Vendor GST Report' },
  // { key: 'dz', value: 'DISH_REPORT', text: 'Dish Report' },
  // { key: 'as', value: 'ORDER_DISHES_REPORT', text: 'Order Dishes Report' },
  // { key: 'aj', value: 'VENDOR_STATISTICS_REPORT', text: 'Vendor Statistics Report' },
  { key: 'aq', value: 'FOOD_NIGHT_AUDIT_REPORT', text: 'Food Court Report' },
];

type FoodCourtTransactionReportProps = {
  transactionReport: {
    foodCourtId: string;
    foodCourtName: string;
    fromDate?: Date;
    toDate?: Date;
  };
  onClose: () => void;
};

const GenerateTransactionReport: FC<FoodCourtTransactionReportProps> = ({
  transactionReport,
  onClose,
}) => {
  const [generateReport, { loading, error }] = useMutation(GENERATE_REPORT);

  const [fromDate, setFromDate] = useState<Date>(transactionReport.fromDate || new Date());

  const [toDate, setToDate] = useState<Date>(transactionReport.toDate || new Date());

  const [type, setType] = useState('FOOD_TRANSACTION_SUMMARY');

  function onSubmit() {
    generateReport({
      variables: {
        id: transactionReport.foodCourtId,
        fromDate: moment(fromDate).format('YYYY-MM-DD'),
        toDate: moment(toDate).format('YYYY-MM-DD'),
        reportType: type,
      },
    }).then(({ data }: any) => {
      if (data.generateFoodCourtReport && data.generateFoodCourtReport.fileLocation)
        window.open(data.generateFoodCourtReport.fileLocation);
    });
  }

  return (
    <div className={theme.information}>
      <h3>Generate Settlement Report</h3>
      <div className={theme.field}>
        <p className={theme.label}>FOOD COURT NAME</p>
        <p className={theme.value}>{transactionReport.foodCourtName}</p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>TYPE</p>
        <Select
          placeholder="Select Type"
          value={type}
          onChange={(e, d) => setType(d.value)}
          options={typeOptions}
        />
      </div>
      <div className={theme.field}>
        <p className={theme.label}>FROM DATE</p>
        <DatePicker
          selected={fromDate}
          onChange={value => setFromDate(value)}
          dateFormat="yyyy-MM-dd"
          style={{ height: '2rem' }}
        />
      </div>
      <div className={theme.field}>
        <p className={theme.label}>TO DATE</p>
        <DatePicker
          selected={toDate}
          onChange={value => setToDate(value)}
          dateFormat="yyyy-MM-dd"
        />
      </div>
      {error ? <Message error header="An error occured. Please try again later." /> : null}
      <Button primary loading={loading} onClick={() => onSubmit()}>
        Submit
      </Button>
    </div>
  );
};

const FoodCourtTransactionReportModal: FC<{
  foodCourtId: string;
  foodCourtName: string;
  onClose: () => void;
}> = ({ foodCourtId, foodCourtName, onClose }) => {
  const data = { foodCourtId, foodCourtName };
  const sections = data
    ? [
        {
          key: 'report',
          name: 'Settlement Report',
          emoji: '📋',
          component: <GenerateTransactionReport transactionReport={data} onClose={onClose} />,
        },
      ]
    : [];
  return (
    <ModalWithSidePanel
      name="REPORT GENERATION"
      loading={false}
      sections={sections}
      onClose={onClose}
    />
  );
};

export default FoodCourtTransactionReportModal;
