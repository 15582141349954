import React, { FC, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useParams } from 'react-router';
import { Divider } from 'semantic-ui-react';

import AdminLayout from 'layouts/AdminLayout';

import Title from 'components/Title';
import ActionPanel from 'components/ActionPanel';
import ErrorDisplay from 'components/ErrorDisplay';
import Loader from 'components/Loader';

import OrdersTable from './OrdersTable';
import OrderDetailModal from './OrderDetailModal';

import FETCH_ORDERS from 'graphql/queries/fetchOrders.graphql';

type OrdersQueryResponse = {
  vendor: {
    id: string;
    name: string;
  };
  ordersByVendor: [
    {
      id: string;
      referenceId: string;
      status: string;
      createdAt: string;
      orderedBy: {
        fullName: string;
      };
      items: {
        itemId: string;
        name: string;
        quantity: number;
      }[];
    }
  ];
};

type OrdersQueryVariables = {
  vendorId: string;
};

const Orders: FC = () => {
  const { vendorId } = useParams();

  const { loading, error, data } = useQuery<OrdersQueryResponse, OrdersQueryVariables>(
    FETCH_ORDERS,
    {
      variables: {
        vendorId: vendorId ? vendorId : '',
      },
    }
  );

  const [orderOnView, setOrderOnView] = useState<string>('');

  if (loading) return <Loader type="screen" />;

  if (error || !data || !data.vendor || !data.ordersByVendor) return <ErrorDisplay type="screen" />;

  const vendor = data.vendor;
  const ordersByVendor = data.ordersByVendor;

  return (
    <AdminLayout>
      <div>
        <Title name={`Orders for ${vendor.name}`} backButtonRoute={`/vendors/${vendorId}`} />
        <Divider />
        <h2>QUICK LINKS</h2>
        <ActionPanel>
          <ActionPanel.Action
            name={'Show Vendor Console'}
            type="link"
            route={`/vendorconsole/${vendorId}`}
          />
        </ActionPanel>
        <Divider />
        <h2>MENU</h2>
        <OrdersTable orders={ordersByVendor} onOrderSelect={orderId => setOrderOnView(orderId)} />
        {orderOnView && orderOnView.length > 0 ? (
          <OrderDetailModal
            orderId={orderOnView}
            onClose={() => setOrderOnView('')}
            vendorId={vendorId ? vendorId : ''}
          />
        ) : null}
      </div>
    </AdminLayout>
  );
};

export default Orders;
