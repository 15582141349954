import ApolloClient from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';

import { logOut } from 'utils/auth';

import config from './configs';

const cache = new InMemoryCache();

export default (token: string) => {
  const client = new ApolloClient({
    cache,
    uri: config.graphqlUri, // todo: get from env
    //credentials: 'include', // todo: backend should set the expected origin
    headers: {
      authorization: token,
    },
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path, extensions }) => {
          if (extensions && extensions.code === 'UNAUTHENTICATED') logOut();
        });
      }
    },
  });
  return client;
};
