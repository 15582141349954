import React, { FC } from 'react';

import getRandomGradient from 'utils/getRandomGradient';

import theme from './theme.scss';

type VendorGridProps = {
  vendors: {
    id: string;
    name: string;
    image: string;
    __typename: string;
  }[];
  onVendorSelect: (vendorId: string, type: string) => void;
};

const VendorGrid: FC<VendorGridProps> = ({ vendors, onVendorSelect }) => {
  return (
    <div className={theme.vendorGrid}>
      {vendors.map(vendor => (
        <div
          className={theme.vendor}
          key={vendor.id}
          onClick={() => onVendorSelect(vendor.id, vendor.__typename)}
        >
          {vendor.image ? (
            <div className={theme.vendorBanner}>
              <div className={theme.blurred} style={{ backgroundImage: `url(${vendor.image})` }} />
              <div className={theme.clear} style={{ backgroundImage: `url(${vendor.image})` }} />
            </div>
          ) : (
            <div className={theme.vendorBanner}>
              <div className={theme.blurred} style={getRandomGradient()} />
            </div>
          )}
          <div className={theme.label}>
            <p className={theme.name}>{vendor.name}</p>
            <p className={theme.type}>{vendor.__typename}</p>
          </div>
        </div>
      ))}
      {vendors && vendors.length > 0 ? null : (
        <div className={theme.emptyMessage}>There are no vendors to show.</div>
      )}
    </div>
  );
};

export default VendorGrid;
