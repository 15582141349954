import React, { FC, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { Button, Modal } from 'semantic-ui-react';
import QrReader from 'react-qr-reader';
import moment from 'moment';

import Loader from 'components/Loader';
import ErrorDisplay from 'components/ErrorDisplay';

import GET_ORDER_STATUS from 'graphql/queries/getOrderStatus.graphql';

import theme from './theme.scss';

const Camera: FC<{ onOrderDetect: (orderId: string) => void; onClose: () => void }> = ({
  onOrderDetect,
  onClose,
}) => {
  const [facingMode, setFacingMode] = useState('environment');
  const [getOrderStatus, { loading, data, error }] = useLazyQuery(GET_ORDER_STATUS);
  function handleScan(data) {
    if (data) {
      getOrderStatus({
        variables: {
          orderId: data,
        },
      });
    }
  }

  function handleError(err) {
    // handle error in qr code reader
  }

  function renderContent() {
    if (loading) return <Loader />;

    if (error)
      return (
        <div className={theme.information}>
          <ErrorDisplay />
        </div>
      );

    if (
      data &&
      (data.order.status !== 'READY' || moment().diff(moment(data.order.createdAt), 'days') !== 0)
    ) {
      return (
        <div className={theme.information}>
          <ErrorDisplay message="There was an issue processing this order. Kindly check your order console." />
        </div>
      );
    }

    if (data && data.order.status === 'READY') {
      onOrderDetect(data.order.id);
      setTimeout(() => onClose(), 100);
    }

    return (
      <>
        <QrReader
          delay={300}
          facingMode={facingMode}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%' }}
        />

        <div>
          <Button
            className={theme.frontCameraButton}
            onClick={() => setFacingMode('user')}
            size="small"
          >
            Front
          </Button>
          <Button
            className={theme.backCameraButton}
            onClick={() => setFacingMode('environment')}
            size="small"
          >
            Back
          </Button>
        </div>
      </>
    );
  }

  return (
    <Modal
      open={true}
      className={theme.codeReaderModal}
      closeOnDimmerClick={true}
      onClose={onClose}
    >
      {renderContent()}
    </Modal>
  );
};

const QRCodeReader: FC<{ onOrderDetect: (orderId: string) => void }> = ({ onOrderDetect }) => {
  const [showReader, setReader] = useState(false);

  return (
    <>
      {showReader ? (
        <Camera onOrderDetect={onOrderDetect} onClose={() => setReader(false)} />
      ) : null}
      <Button
        className={theme.qrCodeButton}
        circular
        onClick={() => setReader(true)}
        icon="camera"
        size="huge"
      />
    </>
  );
};

export default QRCodeReader;
