import React, { FC } from 'react';
import classNames from 'classnames';
import { Button, Table, Responsive } from 'semantic-ui-react';
import moment from 'moment';

import { getStatus } from 'utils/getStatus';

import theme from './theme.scss';

type OrdersTableProps = {
  orders: {
    id: string;
    referenceId: string;
    status: string;
    createdAt: string;
    orderedBy: {
      fullName: string;
    };
  }[];
  onOrderSelect: (orderId: string) => void;
};

const OrdersTable: FC<OrdersTableProps> = ({ orders, onOrderSelect }) => {
  return (
    <div className={theme.orderTable}>
      <Button className={classNames(theme.filter, theme.active)}>All Orders</Button>
      {/*
      <Button className={theme.filter}>Today</Button>
      <Button className={theme.filter}>Past Week</Button>
      <Button className={theme.filter}>Upcoming</Button>
      */}
      <Table unstackable compact className={theme.itemTable}>
        <Table.Header className={theme.itemHeader}>
          <Table.Row>
            <Table.HeaderCell width="5">ORDER #</Table.HeaderCell>
            <Responsive as={Table.HeaderCell} width="4" minWidth={992}>
              ORDERED BY
            </Responsive>
            <Table.HeaderCell width="3" minWidth={992}>
              ORDER TIME
            </Table.HeaderCell>
            <Table.HeaderCell width="4">STATUS</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body className={theme.itemBody}>
          {orders.map(order => (
            <Table.Row
              key={order.id}
              className={theme.itemRow}
              onClick={() => onOrderSelect(order.id)}
            >
              <Table.Cell width="5">{order.referenceId}</Table.Cell>
              <Responsive as={Table.Cell} width="4" minWidth={992}>
                {order.orderedBy ? order.orderedBy.fullName : ''}
              </Responsive>
              <Table.Cell width="4" minWidth={992}>
                {moment(order.createdAt).format('HH:mm a DD MMMM YYYY')}
              </Table.Cell>
              <Table.Cell width="3" className={theme[order.status]}>
                {getStatus(order.status)}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {orders && orders.length > 0 ? null : (
        <div className={theme.emptyMessage}>There are no orders to show.</div>
      )}
    </div>
  );
};

export default OrdersTable;
