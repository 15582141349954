import React, { FC, useState } from 'react';
import { useMutation } from 'react-apollo';
import { Button, Message, Modal } from 'semantic-ui-react';

import ValidatedInput from 'components/ValidatedInput';

import FETCH_VENDORS from 'graphql/queries/fetchVendors.graphql';
import ADD_VENDOR from 'graphql/mutations/addVendor.graphql';

import theme from './theme.scss';

type Vendor = {
  id: string;
  name: string;
  description: string;
};

type AddVendorMutationResponse = {
  vendor: Vendor;
};

type AddVendorVariables = {
  foodCourtId: string;
  name: string;
  description: string;
};

type AddVendorModalProps = {
  onClose: () => void;
};

const AddVendorModal: FC<AddVendorModalProps> = ({ onClose }) => {
  const [addVendor, { loading, error }] = useMutation(ADD_VENDOR, {
    update(cache, { data: { addVendor } }) {
      const fetchVendorsQuery = cache.readQuery<{ vendors: Vendor[] }>({
        query: FETCH_VENDORS,
      });
      if (!fetchVendorsQuery) return;
      const vendors = fetchVendorsQuery.vendors;
      cache.writeQuery({
        query: FETCH_VENDORS,
        data: {
          vendors: vendors.concat([addVendor]),
        },
      });
    },
  });

  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  function onSubmit() {
    addVendor({
      variables: {
        name,
        description,
      },
    }).then(() => onClose());
  }

  function renderContent() {
    return (
      <div className={theme.content}>
        <div className={theme.information}>
          <h3>Add a Vendor</h3>
          <div className={theme.field}>
            <p className={theme.label}>NAME</p>
            <ValidatedInput
              validator="shortText"
              required={true}
              value={name}
              onValueChange={value => setName(value)}
            />
          </div>
          <div className={theme.field}>
            <p className={theme.label}>DESCRIPTION</p>
            <ValidatedInput
              validator="shortText"
              value={description}
              onValueChange={value => setDescription(value)}
            />
          </div>
          {error ? <Message error header="An error occured. Please try again later." /> : null}
          <Button primary loading={loading} onClick={() => onSubmit()}>
            Submit
          </Button>
          <Button basic disabled={loading} onClick={() => onClose()}>
            Cancel
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Modal open={true} className={theme.addVendorModal}>
      {renderContent()}
    </Modal>
  );
};

export default AddVendorModal;
