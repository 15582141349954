import React, { FC, useState } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import { Button, Dropdown, Message, Modal } from 'semantic-ui-react';

import ErrorDisplay from 'components/ErrorDisplay';
import Loader from 'components/Loader';

import ValidatedInput from 'components/ValidatedInput';

import FETCH_DISH from 'graphql/queries/fetchDish.graphql';
import UPDATE_DISH from 'graphql/mutations/updateDish.graphql';
import REMOVE_DISH from 'graphql/mutations/removeDish.graphql';
import FETCH_VENDOR from 'graphql/queries/fetchVendor.graphql';

import theme from './theme.scss';

type Category = {
  id: string;
  name: string;
  __typename: string;
};

type Dish = {
  id: string;
  name: string;
  description: string;
  isVegetarian: string;
  // initialOrderStatus: string;
  hsnCode: string;
  price: number;
  category: Category;
  cgst: number;
  sgst: number;
};

type DishQueryResponse = {
  vendor: {
    categories: Category[];
  };
  dish: Dish;
};

type DishQueryVariables = {
  vendorId: string;
  dishId: string;
};

type UpdateDishProps = {
  onClose: () => void;
  dishId: string;
  vendorId: string;
  canUpdateDish: boolean;
};

type ReadModeProps = {
  dish: Dish;
  enableEditMode: () => void;
  canUpdateDish: boolean;
  onClose: () => void;
};

type EditModeProps = {
  dish: Dish;
  categories: Category[];
  disableEditMode: () => void;
  onClose: () => void;
  vendorId: string;
};

type VendorQueryResponse = {
  vendor: {
    id: string;
    name: string;
    image: string;
    dishes: Dish[];
  };
};

const ReadMode: FC<ReadModeProps> = ({ dish, enableEditMode, canUpdateDish, onClose }) => {
  function getGstInformation(cgst: number) {
    switch (cgst) {
      case 0:
        return 'GST0';
      case 2.5:
        return 'GST5';
      case 6:
        return 'GST12';
      case 9:
        return 'GST18';
      case 14:
        return 'GST28';
      default:
        return '-';
    }
  }

  return (
    <div className={theme.content}>
      <div className={theme.information}>
        <h3>Dish</h3>
        <div className={theme.field}>
          <p className={theme.label}>NAME</p>
          <p className={theme.value}>{dish.name ? dish.name : '-'}</p>
        </div>
        <div className={theme.field}>
          <p className={theme.label}>DESCRIPTION</p>
          <p className={theme.value}>{dish.description ? dish.description : '-'}</p>
        </div>
        <div className={theme.field}>
          <p className={theme.label}>HSN Code</p>
          <p className={theme.value}>{dish.hsnCode ? dish.hsnCode : '-'}</p>
        </div>
        <div className={theme.field}>
          <p className={theme.label}>IS VEGETARIAN</p>
          <p className={theme.value}>{dish.isVegetarian ? 'True' : 'False'}</p>
        </div>
        <div className={theme.field}>
          <p className={theme.label}>PRICE</p>
          <p className={theme.value}>₹ {dish.price ? dish.price : '-'}</p>
        </div>
        <div className={theme.field}>
          <p className={theme.label}>CATEGORIES</p>
          <p className={theme.value}>{dish.category ? dish.category.name : '-'}</p>
        </div>
        <div className={theme.field}>
          <p className={theme.label}>GST</p>
          <p className={theme.value}>{getGstInformation(dish.cgst)}</p>
        </div>
        {/* <div className={theme.field}>
          <p className={theme.label}>INITIAL ORDER STATUS</p>
          <p className={theme.value}>{dish.initialOrderStatus ? dish.initialOrderStatus : '-'}</p>
        </div> */}
        {canUpdateDish ? (
          <Button primary onClick={() => enableEditMode()}>
            Edit
          </Button>
        ) : null}
        <Button basic onClick={() => onClose()}>
          Close
        </Button>
      </div>
    </div>
  );
};

const EditMode: FC<EditModeProps> = ({ dish, categories, disableEditMode, onClose, vendorId }) => {
  const [updateDish, { loading, error }] = useMutation(UPDATE_DISH);

  const [removeDish, { loading: removeLoading, error: removeError }] = useMutation(REMOVE_DISH, {
    update(cache, { data: { removeDish } }) {
      const fetchVendorQuery = cache.readQuery<VendorQueryResponse>({
        query: FETCH_VENDOR,
        variables: {
          vendorId,
        },
      });
      if (!fetchVendorQuery) return;
      const newDishes = fetchVendorQuery.vendor.dishes.filter(dish => dish.id !== removeDish.id);
      const updatedVendor = { ...fetchVendorQuery.vendor, dishes: newDishes };
      cache.writeQuery({
        query: FETCH_VENDOR,
        data: {
          vendor: updatedVendor,
        },
        variables: {
          vendorId,
        },
      });
    },
  });

  const options = categories.map(cat => ({
    key: cat.id,
    text: cat.name,
    value: cat.name,
  }));

  // const [name, setName] = useState<string>(dish.name);
  const [description, setDescription] = useState<string>(dish.description);
  // const [price, setPrice] = useState<number | any>(dish.price);
  // const [isVegetarian, setIsVegetarian] = useState<boolean | any>(dish.isVegetarian);
  // const [category, setCategory] = useState<string | any>(dish.category ? dish.category.name : '');
  // const [gst, setGst] = useState<number | any>(dish.cgst * 2);
  // const [initialOrderStatus, setInitialOrderStatus] = useState<string | any>(
  //   dish.initialOrderStatus ? dish.initialOrderStatus : ''
  // );
  // const [hsnCode, setHsnCode] = useState<string>(dish.hsnCode);

  // const getSelectedCategory = () => {
  //   const selectedCategory = categories.filter(cat => cat.name === category)[0];
  //   return selectedCategory.id;
  // };

  function onSubmit() {
    updateDish({
      variables: {
        dishId: dish.id,
        description,
      },
    }).then(() => disableEditMode());
  }

  function getGstInformation(cgst: number) {
    switch (cgst) {
      case 0:
        return 'GST0';
      case 2.5:
        return 'GST5';
      case 6:
        return 'GST12';
      case 9:
        return 'GST18';
      case 14:
        return 'GST28';
      default:
        return '-';
    }
  }

  return (
    <div className={theme.content}>
      <div className={theme.information}>
        <h3>Editing Dish</h3>
        <div className={theme.field}>
          <p className={theme.label}>NAME</p>
          <p className={theme.value}>{dish.name ? dish.name : '-'}</p>
        </div>
        <div className={theme.field}>
          <p className={theme.label}>DESCRIPTION</p>
          <ValidatedInput
            validator="shortText"
            value={description}
            onValueChange={value => setDescription(value)}
          />
        </div>
        <div className={theme.field}>
          <p className={theme.label}>HSN Code</p>
          <p className={theme.value}>{dish.hsnCode ? dish.hsnCode : '-'}</p>
        </div>
        <div className={theme.field}>
          <p className={theme.label}>IS VEGETARIAN</p>
          <p className={theme.value}>{dish.isVegetarian ? 'True' : 'False'}</p>
        </div>
        <div className={theme.field}>
          <p className={theme.label}>PRICE</p>
          <p className={theme.value}>₹ {dish.price ? dish.price : '-'}</p>
        </div>
        <div className={theme.field}>
          <p className={theme.label}>CATEGORIES</p>
          <p className={theme.value}>{dish.category ? dish.category.name : '-'}</p>
        </div>
        <div className={theme.field}>
          <p className={theme.label}>GST</p>
          <p className={theme.value}>{getGstInformation(dish.cgst)}</p>
        </div>
        {/* <div className={theme.field}>
          <p className={theme.label}>NAME</p>
          <ValidatedInput
            validator="shortText"
            required={true}
            value={name}
            onValueChange={value => setName(value)}
          />
        </div>
        <div className={theme.field}>
          <p className={theme.label}>HSN Code</p>
          <ValidatedInput
            validator="shortText"
            value={hsnCode}
            onValueChange={value => setHsnCode(value)}
          />
        </div>
        <div className={theme.field}>
          <p className={theme.label}>PRICE</p>
          <ValidatedInput
            validator="decimal"
            value={price}
            onValueChange={value => setPrice(value)}
          />
        </div>
        <div className={theme.field}>
          <p className={theme.label}>IS VEGETARIAN</p>
          <Dropdown
            placeholder="Select Type"
            fluid
            selection
            defaultValue={isVegetarian}
            options={[
              {
                key: 'true',
                text: 'True',
                value: true,
              },
              {
                key: 'false',
                text: 'False',
                value: false,
              },
            ]}
            onChange={(e, data) => setIsVegetarian(data.value)}
          />
        </div>
        <div className={theme.field}>
          <p className={theme.label}>CATEGORY</p>
          <Dropdown
            placeholder="Select Category"
            fluid
            selection
            defaultValue={category}
            options={options}
            onChange={(e, data) => setCategory(data.value)}
          />
        </div>
        <div className={theme.field}>
          <p className={theme.label}>GST</p>
          <Dropdown
            placeholder="Select GST"
            fluid
            selection
            defaultValue={gst}
            options={[
              {
                key: '0',
                text: 'GST0',
                value: 0,
              },
              {
                key: '5',
                text: 'GST5',
                value: 5,
              },
              {
                key: '12',
                text: 'GST12',
                value: 12,
              },
              {
                key: '18',
                text: 'GST18',
                value: 18,
              },
              {
                key: '28',
                text: 'GST28',
                value: 28,
              },
            ]}
            onChange={(e, data) => setGst(data.value)}
          />
        </div>
        <div className={theme.field}>
          <p className={theme.label}>INITIAL ORDER STATUS</p>
          <Dropdown
            placeholder="Select Order Status"
            fluid
            selection
            defaultValue={initialOrderStatus}
            options={[
              {
                key: 'preparing',
                text: 'Preparing',
                value: 'PREPARING',
              },
              {
                key: 'ready',
                text: 'Ready',
                value: 'READY',
              },
            ]}
            onChange={(e, data) => setInitialOrderStatus(data.value)}
          />
        </div> */}
        {error || removeError ? (
          <Message error header="An error occured. Please try again later." />
        ) : null}
        <Button primary loading={loading} disabled={removeLoading} onClick={() => onSubmit()}>
          Submit
        </Button>
        <Button
          basic
          loading={removeLoading}
          disabled={loading}
          onClick={() => removeDish({ variables: { dishId: dish.id } }).then(() => onClose())}
        >
          Remove
        </Button>
        <Button basic disabled={loading || removeLoading} onClick={() => disableEditMode()}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

const UpdateDish: FC<UpdateDishProps> = ({ onClose, dishId, canUpdateDish, vendorId }) => {
  const { loading, error, data } = useQuery<DishQueryResponse, DishQueryVariables>(FETCH_DISH, {
    variables: {
      vendorId,
      dishId,
    },
  });
  const [editMode, setEditMode] = useState<boolean>(false);

  function renderContent() {
    if (loading) return <Loader />;

    if (error || !data || !data.dish || !data.vendor.categories) return <ErrorDisplay />;

    if (editMode) {
      return (
        <EditMode
          dish={data.dish}
          categories={data.vendor.categories}
          disableEditMode={() => setEditMode(false)}
          onClose={onClose}
          vendorId={vendorId}
        />
      );
    }

    return (
      <ReadMode
        dish={data.dish}
        onClose={onClose}
        canUpdateDish={canUpdateDish}
        enableEditMode={() => setEditMode(true)}
      />
    );
  }

  return (
    <Modal open={true} className={theme.editDishModal}>
      {renderContent()}
    </Modal>
  );
};

export default UpdateDish;
