import React, { FC } from 'react';
import { Icon } from 'semantic-ui-react';

import theme from './theme.scss';

export type ErrorDisplayProps = {
  type?: 'screen' | 'block';
  message?: string;
};

const ErrorDisplay: FC<ErrorDisplayProps> = ({ type = 'block', message }) => {
  return (
    <div className={theme.errorDisplay} style={{ height: type === 'screen' ? '100vh' : '100%' }}>
      <div className={theme.container}>
        <Icon name="warning circle" size="huge" />
        <p className={theme.message}>
          {message && message !== ''
            ? message
            : "We're having trouble fetching this information. Please try again later."}
        </p>
      </div>
    </div>
  );
};

export default ErrorDisplay;
