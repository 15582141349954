import React, { FC, useState } from 'react';
import { Button, Dimmer, Icon } from 'semantic-ui-react';

import theme from './theme.scss';

export type ConfirmButtonProps = {
  text: string;
  type: 'positive' | 'negative';
  action: any;
  divider?: boolean;
  disabled?: boolean;
};

const ConfirmButton: FC<ConfirmButtonProps> = ({
  text,
  type,
  action,
  divider = false,
  disabled = false,
}) => {
  const [openConfirmModal, toggleConfirmModal] = useState(false);

  function onSubmit() {
    action();
    toggleConfirmModal(false);
  }

  const backgroundColor = () => {
    switch (type) {
      case 'positive':
        return '#12e093';
      case 'negative':
        return '#ff5252';
      default:
        return '#9E9E9E';
    }
  };

  return (
    <div className={theme.confirmButton}>
      <Button
        disabled={disabled}
        onClick={() => toggleConfirmModal(true)}
        fluid
        style={{ backgroundColor: backgroundColor() }}
      >
        {text}
      </Button>
      {divider ? (
        <div className={theme.verticalDivider}>
          <div className={theme.left} />
          <div className={theme.right} />
        </div>
      ) : (
        <div className={theme.verticalDivider} />
      )}
      {openConfirmModal ? (
        <React.Fragment>
          <p className={theme.label}>Confirm Action?</p>
          <div className={theme.actionButtons}>
            <Button className={theme.confirm} circluar onClick={() => onSubmit()}>
              <Icon name="check" style={{ marginTop: '0.3rem' }} />
            </Button>
            <Button className={theme.cancel} circluar onClick={() => toggleConfirmModal(false)}>
              <Icon name="close" style={{ marginTop: '0.3rem' }} />
            </Button>
          </div>
        </React.Fragment>
      ) : null}
      <Dimmer
        active={openConfirmModal}
        onClickOutside={() => toggleConfirmModal(false)}
        page
      ></Dimmer>
    </div>
  );
};

export default ConfirmButton;
