import React, { FC, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useHistory, useParams } from 'react-router-dom';
import { Divider } from 'semantic-ui-react';

import AdminLayout from 'layouts/AdminLayout';
import AddVendorModal from './AddVendorModal';
import Statistics from './Statistics';
import FoodCourtTransactionReportModal from './FoodCourtTransactionReportModal';

import VendorGrid from 'components/VendorGrid';
import ActionPanel from 'components/ActionPanel';
import Title from 'components/Title';
import ErrorDisplay from 'components/ErrorDisplay';
import Loader from 'components/Loader';

import FETCH_FOOD_COURT from 'graphql/queries/fetchFoodCourt.graphql';

type FoodCourtQueryResponse = {
  foodCourt: {
    id: string;
    name: string;
    image: string;
    vendors: {
      id: string;
      name: string;
      image: string;
      analytics: {
        salesRevenue: {
          day: number;
          month: number;
          total: number;
        };
      };
      __typename: string;
    }[];
  };
};

const FoodCourt: FC = () => {
  const { foodCourtId } = useParams();
  const history = useHistory();

  const { loading, error, data } = useQuery<FoodCourtQueryResponse>(FETCH_FOOD_COURT, {
    variables: {
      foodCourtId,
      showAll: true,
    },
  });

  const [openAddVendorModal, toggleAddVendorModal] = useState<boolean>(false);
  const [openTransactionReportModal, toggleTransactionReportModal] = useState(false);

  if (loading) return <Loader type="screen" />;

  if (error || !data || !data.foodCourt) return <ErrorDisplay type="screen" />;

  const foodCourt = data.foodCourt;

  return (
    <AdminLayout>
      <div>
        <Title name={foodCourt.name} backButtonRoute="/" />
        <Divider />
        <h2>QUICK LINKS</h2>
        <ActionPanel>
          <ActionPanel.Action
            name={'Generate Report'}
            type="callback"
            callback={() => toggleTransactionReportModal(true)}
          />
        </ActionPanel>
        <Divider />
        <h2>VENDORS</h2>
        <VendorGrid
          vendors={foodCourt.vendors}
          onVendorSelect={(id, _type) =>
            history.push({ pathname: `/vendors/${id}`, state: { previousState: 'foodcourt' } })
          }
        />
        <Statistics foodCourtId={foodCourt.id} />
        {openAddVendorModal ? <AddVendorModal onClose={() => toggleAddVendorModal(false)} /> : null}
        {openTransactionReportModal ? (
          <FoodCourtTransactionReportModal
            foodCourtId={foodCourt.id}
            foodCourtName={foodCourt.name}
            onClose={() => toggleTransactionReportModal(false)}
          />
        ) : null}
      </div>
    </AdminLayout>
  );
};

export default FoodCourt;
