import React, { FC, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { Button, Divider, } from 'semantic-ui-react';


import ModalWithSidePanel from 'components/ModalWithSidePanel';

import FETCH_ROOM_DETAIL from 'graphql/queries/fetchRoomDetail.graphql';
import GUEST_CHECKIN from 'graphql/mutations/markGuestCheckin.graphql';
import GUEST_CHECKOUT from 'graphql/mutations/markGuestCheckout.graphql';

import theme from './theme.scss';
import ValidatedInput from 'components/ValidatedInput';

type RoomQueryResponse = {
  room: {
    id
    referenceId
    name
    description
    status
    bill: {
      orders: {
        id
        totalPrice
        dishes: {
          id
          name
          quantity
        }[]
      }[]
      occupant: {
        id
        name
        email
        phoneNumber
        checkInTime
        checkOutTime
      }
      totalAmount
    }
  };
};

type RoomQueryVariables = {
  roomId: string;
};

type OrderInformationProps = {
  orders: {
    id
    totalPrice
    dishes: {
      id
      name
      quantity
    }[]
  }[]
  onClose: () => void;
  unbilledAmount: number;
  roomId: string;
};

type RoomInformationProps = {
  room: {
    id
    referenceId
    name
    description
    status
    bill: {
      orders: {
        id
        totalPrice
        dishes: {
          id
          name
          quantity
        }[]
      }[]
      occupant: {
        id
        name
        email
        phoneNumber
        checkInTime
        checkOutTime
      }
      totalAmount
    }
  };
  onClose: () => void;
};

const RoomInformation: FC<RoomInformationProps> = ({ room, onClose }) => {
  const [markGuestCheckin, { loading, error }] = useMutation(GUEST_CHECKIN);
  const bill = room.bill || null;
  const occupant = bill.occupant || null;

  const [name, setName] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  function onSubmit() {
    markGuestCheckin({
      variables: {
        roomId: room.id,
        phoneNumber,
        email,
        name,
      },
    }).then(() => window.location.reload());
  }


  return (
    <div className={theme.information}>
      <div className={theme.field}>
        <p className={theme.label}>ROOM #</p>
        <p className={theme.value}>{room.referenceId}</p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>ROOM NAME #</p>
        <p className={theme.value}>{room.name}</p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>CHECK-IN TIME</p>
        <p className={theme.value}>{(occupant ? occupant.checkInTime : '')}</p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>GUEST NAME</p>
        <p className={theme.value}>{occupant ? occupant.name : ''}</p>
        {!occupant || !occupant.name ? (<ValidatedInput
            validator="shortText"
            value={name}
            onValueChange={value => setName(value)}
          />): null}
      </div>
      <div className={theme.field}>
        <p className={theme.label}>EMAIL</p>
        <p className={theme.value}>{occupant ? occupant.email : ''}</p>
        {!occupant || !occupant.email ? (<ValidatedInput
            validator="shortText"
            value={email}
            onValueChange={value => setEmail(value)}
          />) : null}
      </div>
      <div className={theme.field}>
        <p className={theme.label}>PHONE NUMBER</p>
        <p className={theme.value}>{occupant ? occupant.phoneNumber : ''}</p>
        {!occupant || !occupant.phoneNumber ? (<ValidatedInput
            validator="shortText"
            value={phoneNumber}
            onValueChange={value => setPhoneNumber(value)}
          />) : null}
      </div>
      {!occupant || !occupant.phoneNumber ? (<Button
        positive
        loading={loading}
        onClick={() => onSubmit()}
      >
        Check-In Guest
      </Button>): null}
    </div>
  );
};

const OrderInformation: FC<OrderInformationProps> = ({ roomId, orders, unbilledAmount, onClose }) => {
  const [markGuestCheckout, { loading, error, data }] = useMutation(GUEST_CHECKOUT);

  if(data)
    window.location.reload();
    
  return (
    <div className={theme.information}>
      <div className={theme.field}>
          <p className={theme.label}>DISHES</p>
          {orders.map((order) => (
            <div>
              <Divider/>
                {(order ? order.dishes.map((dish) => (
                  <p className={theme.value}>{dish.name} X {dish.quantity}</p>
                )) : '')}
                <p className={theme.value}>Total Price : {order.totalPrice}</p>
              <Divider/>
            </div>
          ))}

          <p className={theme.value}>Total Bill Amount : {unbilledAmount}</p>
      </div>
      <Button
        positive
        loading={loading}
        onClick={() => markGuestCheckout({ variables: { roomId } })}
      >
        Check-Out And Pay
      </Button>
    </div>
  );
};

const RoomDetailModal: FC<{ roomId: string; vendorId: string; onClose: () => void }> = ({
  roomId,
  vendorId,
  onClose,
}) => {
  const { loading, error, data } = useQuery<RoomQueryResponse, RoomQueryVariables>(
    FETCH_ROOM_DETAIL,
    {
      variables: { roomId },
    }
  );
  
  const sections = data
    ? [
        {
          key: 'information',
          name: 'Details',
          emoji: '📋',
          component: <RoomInformation room={data.room} onClose={onClose} />,
        },
        {
          key: 'information',
          name: 'Order',
          emoji: '🍱',
          component: <OrderInformation orders={data.room.bill.orders} onClose={onClose} roomId= {roomId} unbilledAmount={data.room.bill.totalAmount} />,
        },
      ]
    : [];

  return (
    <ModalWithSidePanel
      name="ORDER"
      loading={loading}
      error={!!error || !data || !data.room}
      sections={sections}
      onClose={onClose}
    />
  );
};

export default RoomDetailModal;
