import React, { FC } from 'react';
import { useQuery } from 'react-apollo';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { Button, Divider, Table, Responsive } from 'semantic-ui-react';
import classNames from 'classnames';

import FETCH_VENDOR_STATISTICS from 'graphql/queries/fetchVendorStatistics.graphql';

import theme from './theme.scss';

type VendorQueryResponse = {
  vendor: {
    id: string;
    organizations: {
      id: string;
      name: string;
      analytics: {
        daysWalletRevenue: number;
        daysCashRevenue: number;
        daysTotalRevenue: number;
      };
    }[];
    analytics: {
      daysHourlyStats: {
        hour: number;
        ordersCount: number;
        salesRevenue: number;
      }[];
      ordersCount: {
        day: number;
        month: number;
        total: number;
      };
      salesRevenue: {
        day: number;
        month: number;
        total: number;
      };
    };
  };
};

type VendorQueryVariables = {
  vendorId: string;
};

const XTick: FC = props => {
  // @ts-ignore
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666">
        {payload.value === 0
          ? ''
          : `${payload.value % 12 || 12} ${payload.value < 12 ? 'AM' : 'PM'}`}
      </text>
    </g>
  );
};

const YTick: FC = props => {
  // @ts-ignore
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={4} dx={0} textAnchor="end" fill="#666">
        {payload.value === 0 ? '' : `₹ ${payload.value}`}
      </text>
    </g>
  );
};

const CustomTooltip = props => {
  // @ts-ignore
  const { active, payload } = props;

  if (active && payload && payload[0]) {
    return (
      <div className={theme.tooltip}>
        <p className={theme.label}>REVENUE</p>
        <p className={theme.value}>{`₹ ${payload[0].value}`}</p>
        <p className={theme.label}>NUMBER OF ORDERS</p>
        <p className={theme.value}>{payload[0].payload.ordersCount}</p>
      </div>
    );
  }

  return null;
};

const Statistics: FC<{ vendorId: string }> = ({ vendorId }) => {
  const { loading, error, data } = useQuery<VendorQueryResponse, VendorQueryVariables>(
    FETCH_VENDOR_STATISTICS,
    {
      variables: { vendorId },
    }
  );

  if (loading) {
    return null;
  }

  if (error || !(data && data.vendor && data.vendor.analytics)) {
    return null;
  }

  const vendor = data.vendor;
  const organizations = vendor.organizations;
  const analytics = vendor.analytics;

  return (
    <>
      <Divider />
      <h2>STATISTICS</h2>
      <div className={theme.statisticsContainer}>
        <div className={theme.statistic}>
          <h5>NUMBER OF ORDERS (TODAY)</h5>
          <p>
            {analytics.ordersCount.day}
            <span> orders</span>
          </p>
        </div>
        <div className={theme.statistic}>
          <h5>NUMBER OF ORDERS (THIS MONTH)</h5>
          <p>
            {analytics.ordersCount.month}
            <span> orders</span>
          </p>
        </div>
        <div className={theme.statistic}>
          <h5>NUMBER OF ORDERS (ALL TIME)</h5>
          <p>
            {analytics.ordersCount.total}
            <span> orders</span>
          </p>
        </div>
        <div className={theme.statistic}>
          <h5>TOTAL REVENUE (TODAY)</h5>
          <p>
            <span>₹ </span>
            {analytics.salesRevenue.day}
          </p>
        </div>
        <div className={theme.statistic}>
          <h5>TOTAL REVENUE (THIS MONTH)</h5>
          <p>
            <span>₹ </span>
            {analytics.salesRevenue.month}
          </p>
        </div>
        <div className={theme.statistic}>
          <h5>TOTAL REVENUE (ALL TIME)</h5>
          <p>
            <span>₹ </span>
            {analytics.salesRevenue.total}
          </p>
        </div>
      </div>
      {analytics.salesRevenue.day !== 0 ? (
        <div style={{ width: '100%', height: 300 }}>
          <ResponsiveContainer>
            <AreaChart
              data={analytics.daysHourlyStats}
              margin={{
                top: 5,
                bottom: 5,
              }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="2 2" />
              <XAxis
                dataKey="hour"
                domain={[0, 24]}
                type="number"
                tickCount={13}
                tick={<XTick />}
              />
              <YAxis unit=" ₹" tick={<YTick />} />
              <Tooltip content={<CustomTooltip />} />
              <Area
                type="monotone"
                dataKey="salesRevenue"
                stroke="#8884d8"
                fillOpacity={1}
                fill="url(#colorUv)"
                dot={true}
                activeDot={{ r: 8 }}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      ) : null}
      {analytics.salesRevenue.day !== 0 && organizations && organizations.length ? (
        <div className={theme.breakup}>
          <Button className={classNames(theme.filter, theme.active)}>All Companies</Button>
          <Table unstackable compact className={theme.itemTable}>
            <Table.Header className={theme.itemHeader}>
              <Table.Row>
                <Table.HeaderCell width="5">COMPANY</Table.HeaderCell>
                <Responsive as={Table.HeaderCell} width="4" minWidth={992}>
                  WALLET CONSUMPTION (TODAY)
                </Responsive>
                <Table.HeaderCell width="3" minWidth={992}>
                  PURCHASES (TODAY)
                </Table.HeaderCell>
                <Table.HeaderCell width="4">TOTAL (TODAY)</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body className={theme.itemBody}>
              {organizations.map(organization => (
                <Table.Row key={organization.id} className={theme.itemRow}>
                  <Table.Cell width="5">{organization.name}</Table.Cell>
                  <Responsive as={Table.Cell} width="4" minWidth={992}>
                    ₹ {organization.analytics.daysWalletRevenue}
                  </Responsive>
                  <Table.Cell width="3" minWidth={992}>
                    ₹ {organization.analytics.daysCashRevenue}
                  </Table.Cell>
                  <Table.Cell width="4">₹ {organization.analytics.daysTotalRevenue}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      ) : null}
    </>
  );
};

export default Statistics;
