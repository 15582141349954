import React, { FC } from 'react';
import { withRouter } from 'react-router-dom';

import Navbar from 'components/Navbar';

import theme from './theme.scss';

const ConsoleLayout: FC = props => {
  return (
    <div className={theme.consoleLayout}>
      <Navbar />
      <div className={theme.content}>{props.children}</div>
    </div>
  );
};

export default withRouter(ConsoleLayout);
