import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { Icon } from 'semantic-ui-react';

import theme from './theme.scss';

export type TitleProps = {
  name: string;
  backButtonRoute?: string;
};

const Title: FC<TitleProps> = ({ name, backButtonRoute }) => {
  const history = useHistory();

  function backButton() {
    if (!backButtonRoute || !backButtonRoute.length) return null;

    return (
      <div className={theme.navigateBack} onClick={() => history.push(backButtonRoute)}>
        <Icon name="arrow left" />
      </div>
    );
  }

  return (
    <div className={theme.title}>
      {backButton()}
      <h1>{name}</h1>
    </div>
  );
};

export default Title;
