import React, { FC } from 'react';
import { useMutation } from 'react-apollo';
import classNames from 'classnames';
import { Button, Checkbox, Loader, Table, Responsive } from 'semantic-ui-react';

import SWITCH_DISH_AVAILABILITY from 'graphql/mutations/switchDishAvailability.graphql';

import theme from './theme.scss';

type MenuTableProps = {
  dishes: {
    id: string;
    name: string;
    price: number;
    isOnMenu: boolean;
    category: { name: string };
  }[];
  onDishSelect: (dishId: string) => void;
  canSwitchDishAvailability: boolean;
};

type IsOnMenuProps = {
  dishId: string;
  isOnMenu: any;
  canSwitchDishAvailability: boolean;
};

const IsOnMenu: FC<IsOnMenuProps> = ({ dishId, isOnMenu, canSwitchDishAvailability }) => {
  const [switchAvailability, { loading }] = useMutation(SWITCH_DISH_AVAILABILITY);

  function onSwitch(e) {
    e.stopPropagation();
    switchAvailability({
      variables: {
        dishId,
        isOnMenu: !isOnMenu,
      },
    });
  }

  return (
    <div className={theme.availabilitySwitch}>
      <Checkbox
        toggle
        checked={isOnMenu}
        onChange={onSwitch}
        disabled={!canSwitchDishAvailability}
      />
      <Loader className={theme.loader} active={loading} inline size="mini" />
    </div>
  );
};

const MenuTable: FC<MenuTableProps> = ({ dishes, onDishSelect, canSwitchDishAvailability }) => {
  return (
    <div className={theme.menuTable}>
      <Button className={classNames(theme.filter, theme.active)}>All Dishes</Button>
      <Table unstackable compact className={theme.itemTable}>
        <Table.Header className={theme.itemHeader}>
          <Table.Row>
            <Table.HeaderCell width="5">NAME</Table.HeaderCell>
            <Responsive as={Table.HeaderCell} width="4" minWidth={992}>
              CATEGORY
            </Responsive>
            <Table.HeaderCell width="3" minWidth={992}>
              PRICE
            </Table.HeaderCell>
            <Table.HeaderCell width="4">AVAILABILITY</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body className={theme.itemBody}>
          {dishes.map(dish => (
            <Table.Row
              key={dish.id}
              className={theme.itemRow}
              onClick={() => onDishSelect(dish.id)}
            >
              <Table.Cell width="5">{dish.name}</Table.Cell>
              <Responsive as={Table.Cell} width="4" minWidth={992}>
                {dish.category ? dish.category.name : ''}
              </Responsive>
              <Table.Cell width="3" minWidth={992}>
                ₹ {dish.price}
              </Table.Cell>
              <Table.Cell width="4">
                <IsOnMenu
                  dishId={dish.id}
                  isOnMenu={dish.isOnMenu}
                  canSwitchDishAvailability={canSwitchDishAvailability}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {dishes && dishes.length > 0 ? null : (
        <div className={theme.emptyMessage}>There are no dishes added yet.</div>
      )}
    </div>
  );
};

export default MenuTable;
