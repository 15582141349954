import React, { FC, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { Button, Dropdown, Message, Modal } from 'semantic-ui-react';

import ErrorDisplay from 'components/ErrorDisplay';
import Loader from 'components/Loader';

import ValidatedInput from 'components/ValidatedInput';

import FETCH_CATEGORIES from 'graphql/queries/fetchCategories.graphql';
import FETCH_VENDOR from 'graphql/queries/fetchVendor.graphql';
import CREATE_DISH from 'graphql/mutations/createDish.graphql';

import theme from './theme.scss';

type Category = {
  id: string;
  name: string;
  __typename: string;
};

type Dish = {
  id: string;
  name: string;
  description: string;
  price: number;
  isOnMenu: boolean;
  initialOrderStatus: string;
  category: Category;
  hsnCode: string;
  cgst: number;
  sgst: number;
};

type VendorQueryResponse = {
  vendor: {
    id: string;
    name: string;
    image: string;
    dishes: Dish[];
  };
};

type CreateDishModalProps = {
  vendorId: string;
  onClose: () => void;
};

type CreateDishProps = {
  categories: Category[];
  vendorId: string;
  onClose: () => void;
};

const CreateDish: FC<CreateDishProps> = ({ categories, vendorId, onClose }) => {
  const [createDish, { loading, error }] = useMutation(CREATE_DISH, {
    update(cache, { data: { createDish } }) {
      const fetchVendorQuery = cache.readQuery<VendorQueryResponse>({
        query: FETCH_VENDOR,
        variables: {
          vendorId,
        },
      });
      if (!fetchVendorQuery) return;
      const newDishes = [createDish, ...fetchVendorQuery.vendor.dishes];
      const updatedVendor = { ...fetchVendorQuery.vendor, dishes: newDishes };
      cache.writeQuery({
        query: FETCH_VENDOR,
        data: {
          vendor: updatedVendor,
        },
        variables: {
          vendorId,
        },
      });
    },
  });

  const options = categories.map(cat => ({
    key: cat.id,
    text: cat.name,
    value: cat.name,
  }));

  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [price, setPrice] = useState<number | any>(0);
  const [isVegetarian, setIsVegetarian] = useState<boolean | any>(true);
  const [category, setCategory] = useState<string | any>();
  const [initialOrderStatus, setInitialOrderStatus] = useState<string | any>();
  const [hsnCode, setHsnCode] = useState<string>('');
  const [gst, setGst] = useState<number | any>(5);

  const getSelectedCategory = () => {
    const selectedCategory = categories.filter(cat => cat.name === category)[0];
    return selectedCategory.id;
  };

  function onSubmit() {
    createDish({
      variables: {
        vendorId,
        name,
        description,
        isVegetarian,
        initialOrderStatus,
        hsnCode,
        price: parseInt(price),
        categoryId: getSelectedCategory(),
        cgst: gst / 2,
        sgst: gst / 2,
      },
    }).then(() => onClose());
  }

  return (
    <div className={theme.content}>
      <div className={theme.information}>
        <h3>Create a Dish</h3>
        <div className={theme.field}>
          <p className={theme.label}>NAME</p>
          <ValidatedInput
            validator="shortText"
            required={true}
            value={name}
            onValueChange={value => setName(value)}
          />
        </div>
        <div className={theme.field}>
          <p className={theme.label}>DESCRIPTION</p>
          <ValidatedInput
            validator="shortText"
            value={description}
            onValueChange={value => setDescription(value)}
          />
        </div>
        <div className={theme.field}>
          <p className={theme.label}>HSN Code</p>
          <ValidatedInput
            validator="shortText"
            value={hsnCode}
            onValueChange={value => setHsnCode(value)}
          />
        </div>
        <div className={theme.field}>
          <p className={theme.label}>IS VEGETARIAN</p>
          <Dropdown
            placeholder="Select Type"
            fluid
            selection
            defaultValue={isVegetarian}
            options={[
              {
                key: 'true',
                text: 'True',
                value: true,
              },
              {
                key: 'false',
                text: 'False',
                value: false,
              },
            ]}
            onChange={(e, data) => setIsVegetarian(data.value)}
          />
        </div>
        <div className={theme.field}>
          <p className={theme.label}>PRICE</p>
          <ValidatedInput
            validator="decimal"
            value={price}
            onValueChange={value => setPrice(value)}
          />
        </div>
        <div className={theme.field}>
          <p className={theme.label}>CATEGORY</p>
          <Dropdown
            placeholder="Select Category"
            fluid
            selection
            defaultValue={category}
            options={options}
            onChange={(e, data) => setCategory(data.value)}
          />
        </div>
        <div className={theme.field}>
          <p className={theme.label}>GST</p>
          <Dropdown
            placeholder="Select GST"
            fluid
            selection
            defaultValue={gst}
            options={[
              {
                key: '0',
                text: 'GST0',
                value: 0,
              },
              {
                key: '5',
                text: 'GST5',
                value: 5,
              },
              {
                key: '12',
                text: 'GST12',
                value: 12,
              },
              {
                key: '18',
                text: 'GST18',
                value: 18,
              },
              {
                key: '28',
                text: 'GST28',
                value: 28,
              },
            ]}
            onChange={(e, data) => setGst(data.value)}
          />
        </div>
        {/* <div className={theme.field}>
          <p className={theme.label}>INITIAL ORDER STATUS</p>
          <Dropdown
            placeholder="Select Order Status"
            fluid
            selection
            defaultValue={initialOrderStatus}
            options={[
              {
                key: 'preparing',
                text: 'Preparing',
                value: 'PREPARING',
              },
              {
                key: 'ready',
                text: 'Ready',
                value: 'READY',
              },
            ]}
            onChange={(e, data) => setInitialOrderStatus(data.value)}
          />
        </div> */}
        {error ? <Message error header="An error occured. Please try again later." /> : null}
        <Button primary loading={loading} onClick={() => onSubmit()}>
          Submit
        </Button>
        <Button basic disabled={loading} onClick={() => onClose()}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

const CreateDishModal: FC<CreateDishModalProps> = ({ vendorId, onClose }) => {
  const { loading, error, data } = useQuery(FETCH_CATEGORIES, {
    variables: {
      vendorId,
    },
  });

  function renderContent() {
    if (loading) return <Loader />;

    if (error || !data || !data.vendor.categories) return <ErrorDisplay />;

    return <CreateDish categories={data.vendor.categories} vendorId={vendorId} onClose={onClose} />;
  }

  return (
    <Modal open={true} className={theme.createDishModal}>
      {renderContent()}
    </Modal>
  );
};

export default CreateDishModal;
