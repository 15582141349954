import React, { FC } from 'react';
import { useQuery } from 'react-apollo';
import { useHistory } from 'react-router-dom';

import AdminLayout from 'layouts/AdminLayout';
import { Divider } from 'semantic-ui-react';

import ActionPanel from 'components/ActionPanel';
import Title from 'components/Title';
import ErrorDisplay from 'components/ErrorDisplay';
import Loader from 'components/Loader';

import VendorGrid from 'components/VendorGrid';

import FETCH_VENDOR_DASHBOARD from 'graphql/queries/fetchVendorDashboard.graphql';

import theme from './theme.scss';

type VendorDashboardQueryResponse = {
  vendorDashboard: {
    id: string;
    name: string;
    image: string;
    __typename: string;
  }[];
};

const quickLinks = [
  {
    name: 'Settings',
    route: '/settings',
  },
];

const Dashboard: FC = () => {
  const history = useHistory();

  const { loading, error, data } = useQuery<VendorDashboardQueryResponse>(FETCH_VENDOR_DASHBOARD);

  if (loading) return <Loader type="screen" />;

  if (error || !data || !data.vendorDashboard) return <ErrorDisplay type="screen" />;

  const vendors = data.vendorDashboard;

  return (
    <AdminLayout>
      <div className={theme.dashboard}>
        <Title name="Dashboard" />
        <Divider />
        <h2>QUICK LINKS</h2>
        <ActionPanel>
          {quickLinks.map(link => (
            <ActionPanel.Action key={link.route} name={link.name} type="link" route={link.route} />
          ))}
        </ActionPanel>
        <Divider />
        <h2>MY FOOD COURTS</h2>
        <VendorGrid
          vendors={vendors}
          onVendorSelect={(id, type) =>
            type === 'FoodCourt'
              ? history.push(`/foodcourts/${id}`)
              : history.push(`/vendors/${id}`)
          }
        />
      </div>
    </AdminLayout>
  );
};

export default Dashboard;
