import React, { FC, useState } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import { Button, Message } from 'semantic-ui-react';

import ModalWithSidePanel from 'components/ModalWithSidePanel';
import ValidatedInput from 'components/ValidatedInput';

import FETCH_VENDOR_DETAIL from 'graphql/queries/fetchVendorDetail.graphql';
import UPDATE_VENDOR from 'graphql/mutations/updateVendor.graphql';

import theme from './theme.scss';

type VendorQueryResponse = {
  vendor: {
    id: string;
    name: string;
    description: string;
    permissions: {
      canUpdateVendor: boolean;
    };
  };
};

type VendorQueryVariables = {
  vendorId: string;
};

type VendorInformationProps = {
  vendor: {
    id: string;
    name: string;
    description: string;
    permissions: { canUpdateVendor: boolean };
  };
  onClose: () => void;
};

const VendorInformation: FC<VendorInformationProps> = ({ vendor, onClose }) => {
  const [updateVendor, { loading, error }] = useMutation(UPDATE_VENDOR);

  const [editMode, setEditMode] = useState<boolean>(false);

  const [description, setDescription] = useState<string>(vendor.description);

  function onSubmit() {
    updateVendor({
      variables: {
        vendorId: vendor.id,
        description,
      },
    }).then(() => setEditMode(false));
  }

  function enableEditMode() {
    setEditMode(true);
  }

  function disableEditMode() {
    setEditMode(false);
    setDescription(vendor.description);
  }

  if (editMode) {
    return (
      <div className={theme.information}>
        <h3>Editing Vendor</h3>
        <div className={theme.field}>
          <p className={theme.label}>NAME</p>
          <p className={theme.value}>{vendor.name}</p>
        </div>
        <div className={theme.field}>
          <p className={theme.label}>DESCRIPTION</p>
          <ValidatedInput
            validator="shortText"
            required={false}
            value={description}
            onValueChange={value => setDescription(value)}
          />
        </div>
        {error ? <Message error header="An error occured. Please try again later." /> : null}
        <Button primary loading={loading} onClick={() => onSubmit()}>
          Submit
        </Button>
        <Button basic disabled={loading} onClick={disableEditMode}>
          Cancel
        </Button>
      </div>
    );
  }

  return (
    <div className={theme.information}>
      <div className={theme.field}>
        <p className={theme.label}>NAME</p>
        <p className={theme.value}>{vendor.name}</p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>DESCRIPTION</p>
        <p className={theme.value}>{vendor.description}</p>
      </div>
      {vendor.permissions.canUpdateVendor ? (
        <Button primary onClick={enableEditMode}>
          Edit
        </Button>
      ) : null}
      <Button basic onClick={() => onClose()}>
        Close
      </Button>
    </div>
  );
};

const VendorDetailModal: FC<{ vendorId: string; onClose: () => void }> = ({
  vendorId,
  onClose,
}) => {
  const { loading, error, data } = useQuery<VendorQueryResponse, VendorQueryVariables>(
    FETCH_VENDOR_DETAIL,
    {
      variables: { vendorId },
    }
  );

  const sections = data
    ? [
        {
          key: 'information',
          name: 'Details',
          emoji: '📋',
          component: <VendorInformation vendor={data.vendor} onClose={onClose} />,
        },
      ]
    : [];

  return (
    <ModalWithSidePanel
      name="VENDOR"
      loading={loading}
      error={!!error || !data || !data.vendor}
      sections={sections}
      onClose={onClose}
    />
  );
};

export default VendorDetailModal;
