import React, { FC } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';

import Navbar from 'components/Navbar';

import theme from './theme.scss';

const DefaultLayout: FC = props => {
  return (
    <div className={theme.defaultLayout}>
      <Navbar size={'thin'} />
      <div className={theme.content}>
        <Grid>
          <Grid.Row centered>
            <Grid.Column widescreen={7} largeScreen={8} computer={11} tablet={12} mobile={15}>
              {props.children}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
};

export default withRouter(DefaultLayout);
