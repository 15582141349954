import React, { FC, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useParams } from 'react-router';
import { Divider } from 'semantic-ui-react';

import AdminLayout from 'layouts/AdminLayout';

import Title from 'components/Title';
import ActionPanel from 'components/ActionPanel';
import ErrorDisplay from 'components/ErrorDisplay';
import Loader from 'components/Loader';

import RoomsTable from './RoomsTable';
import RoomDetailModal from './RoomDetailsModal';

import FETCH_ROOMS from 'graphql/queries/fetchVendorRooms.graphql';

type RoomsQueryResponse = {
  vendor: {
    id: string;
    name: string;
    rooms: [
      {
        id: string;
        referenceId: string;
        name: string;
        description: string;
        status: string;
      }
    ];
  };
  rooms: [
    {
      id: string;
      referenceId: string;
      name: string;
      description: string;
      status: string;
    }
  ];
};

type RoomsQueryVariables = {
  vendorId: string;
};

const Orders: FC = () => {
  const { vendorId } = useParams<{vendorId: string}>();

  const { loading, error, data } = useQuery<RoomsQueryResponse, RoomsQueryVariables>(
    FETCH_ROOMS,
    {
      variables: {
        vendorId: vendorId ? vendorId : '',
      },
    }
  );


  const [roomOnView, setRoomOnView] = useState<string>('');

  if (loading) return <Loader type="screen" />;

  if (error || !data || !data.vendor || !data.vendor.rooms) return <ErrorDisplay type="screen" />;

  const vendor = data.vendor;
  const rooms = vendor.rooms;

  return (
    <AdminLayout>
      <div>
        <Title name={`Rooms for ${vendor.name}`} backButtonRoute={`/vendors/${vendorId}`} />
        <Divider />
        <h2>MENU</h2>
        <RoomsTable rooms={rooms} onRoomSelect={roomId => setRoomOnView(roomId)} />
        {roomOnView && roomOnView.length > 0 ? (
          <RoomDetailModal
            roomId={roomOnView}
            onClose={() => setRoomOnView('')}
            vendorId={vendorId ? vendorId : ''}
          />
        ) : null}
      </div>
    </AdminLayout>
  );
};

export default Orders;
