import React, { FC } from 'react';
import { useQuery } from 'react-apollo';
import { Button, Divider, Table, Responsive } from 'semantic-ui-react';
import classNames from 'classnames';

import FETCH_FOOD_COURT_STATISTICS from 'graphql/queries/fetchFoodCourtStatistics.graphql';

import theme from './theme.scss';

type FoodCourtQueryResponse = {
  foodCourt: {
    id: string;
    vendors: {
      id: string;
      name: string;
      analytics: {
        salesRevenue: {
          day: number;
          month: number;
          total: number;
        };
      };
    }[];
  };
};

type FoodCourtQueryVariables = {
  foodCourtId: string;
};

const Statistics: FC<{ foodCourtId: string }> = ({ foodCourtId }) => {
  const { loading, error, data } = useQuery<FoodCourtQueryResponse, FoodCourtQueryVariables>(
    FETCH_FOOD_COURT_STATISTICS,
    {
      variables: { foodCourtId },
    }
  );

  if (loading) {
    return null;
  }

  if (error || !(data && data.foodCourt)) {
    return null;
  }

  const vendors = data.foodCourt.vendors;

  return (
    <>
      <Divider />
      <h2>STATISTICS</h2>
      <div className={theme.breakup}>
        <Button className={classNames(theme.filter, theme.active)}>All Vendors</Button>
        <Table unstackable compact className={theme.itemTable}>
          <Table.Header className={theme.itemHeader}>
            <Table.Row>
              <Table.HeaderCell width="5">VENDOR</Table.HeaderCell>
              <Responsive as={Table.HeaderCell} width="4" minWidth={992}>
                REVENUE (TODAY)
              </Responsive>
              <Table.HeaderCell width="3" minWidth={992}>
                REVENUE (THIS MONTH)
              </Table.HeaderCell>
              <Table.HeaderCell width="4">REVENUE (ALL TIME)</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body className={theme.itemBody}>
            {vendors.map(vendor => (
              <Table.Row key={vendor.id} className={theme.itemRow}>
                <Table.Cell width="5">{vendor.name}</Table.Cell>
                <Responsive as={Table.Cell} width="4" minWidth={992}>
                  ₹ {vendor.analytics.salesRevenue.day}
                </Responsive>
                <Table.Cell width="3" minWidth={992}>
                  ₹ {vendor.analytics.salesRevenue.month}
                </Table.Cell>
                <Table.Cell width="4">₹ {vendor.analytics.salesRevenue.total}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {vendors.length > 0 ? null : (
          <div className={theme.emptyMessage}>There are no vendors added yet.</div>
        )}
      </div>
    </>
  );
};

export default Statistics;
