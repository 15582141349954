import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from 'react-apollo';
import { Button, Dropdown, Message } from 'semantic-ui-react';
import classNames from 'classnames';
import moment from 'moment';

import ModalWithSidePanel from 'components/ModalWithSidePanel';

import { getFullStatus } from 'utils/getStatus';

import FETCH_ORDER_DETAIL from 'graphql/queries/fetchOrderDetail.graphql';
import FETCH_ORDERS from 'graphql/queries/fetchOrders.graphql';
import REMOVE_ORDER from 'graphql/mutations/removeOrder.graphql';
import UPDATE_ORDER from 'graphql/mutations/updateOrder.graphql';

import theme from './theme.scss';

type OrdersQueryResponse = {
  vendor: {
    id: string;
    name: string;
  };
  ordersByVendor: [
    {
      id: string;
      referenceId: string;
      status: string;
      createdAt: string;
      orderedBy: {
        fullName: string;
        phoneNumber: string;
      };
      dishes: {
        id: string;
        name: string;
        quantity: number;
        price: number;
      }[];
      grossPrice: number;
      taxes: number;
      paymentMode: string;
      totalPrice: number;
    }
  ];
};

type OrderQueryResponse = {
  order: {
    id: string;
    referenceId: string;
    status: string;
    createdAt: string;
    orderedBy: {
      fullName: string;
      phoneNumber: string;
    };
    feedback: {
      rating: number;
      comments: string;
    };
    spot: {
      name: string;
    };
    dishes: {
      id: string;
      name: string;
      quantity: number;
      price: number;
    }[];
    grossPrice: number;
    taxes: number;
    paymentMode: string;
    totalPrice: number;
  };
};

type OrderQueryVariables = {
  orderId: string;
};

type OrderInformationProps = {
  order: {
    id: string;
    referenceId: string;
    status: string;
    createdAt: string;
    orderedBy: {
      fullName: string;
      phoneNumber: string;
    };
    spot: {
      name: string;
    };
    dishes: {
      id: string;
      name: string;
      quantity: number;
      price: number;
    }[];
    grossPrice: number;
    taxes: number;
    paymentMode: string;
    totalPrice: number;
  };
  onClose: () => void;
  vendorId: string;
};


function showPaymentMode(paymentMode){
  if(paymentMode === 'BILL_TO_SPOT')
    return 'PAYING LATER';
  else
    return 'PAID';
}

const OrderInformation: FC<OrderInformationProps> = ({ order, onClose, vendorId }) => {
  const [removeOrder, { loading, error }] = useMutation(REMOVE_ORDER, {
    update(cache, { data: { removeOrder } }) {
      const fetchOrdersQuery = cache.readQuery<OrdersQueryResponse>({
        query: FETCH_ORDERS,
        variables: {
          vendorId,
        },
      });
      if (!fetchOrdersQuery) return;
      cache.writeQuery({
        query: FETCH_ORDERS,
        data: {
          vendor: fetchOrdersQuery.vendor,
          ordersByVendor: fetchOrdersQuery.ordersByVendor.filter(
            order => order.id !== removeOrder.id
          ),
        },
        variables: {
          vendorId,
        },
      });
    },
  });

  return (
    <div className={theme.information}>
      <div className={theme.field}>
        <p className={theme.label}>ORDER #</p>
        <p className={theme.value}>{order.referenceId}</p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>ORDER TIME</p>
        <p className={theme.value}>{moment(order.createdAt).format('HH:mm a DD MMMM YYYY')}</p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>ORDERED BY</p>
        <p className={theme.value}>{order.orderedBy ? order.orderedBy.fullName : ''}</p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>PHONE NUMBER</p>
        <p className={theme.value}>{order.orderedBy ? order.orderedBy.phoneNumber : ''}</p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>STATUS</p>
        <p className={classNames(theme.value, theme[order.status])}>
          {getFullStatus(order.status)}
        </p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>PAYMENT MODE</p>
        <p className={theme.value}>{showPaymentMode(order.paymentMode)}</p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>DISHES</p>
        {order.dishes.map(dish => (
          <p key={dish.id} className={theme.value}>
            {dish.quantity} x {dish.name} - ₹ {dish.price}
          </p>
        ))}
      </div>
      <div className={theme.field}>
        <p className={theme.label}>GROSS TOTAL</p>
        <p className={theme.value}>₹ {order.grossPrice}</p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>TAXES</p>
        <p className={theme.value}>₹ {order.taxes}</p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>GRAND TOTAL</p>
        <p className={theme.value}>₹ {order.totalPrice}</p>
      </div>
      <div className={theme.field}>
        <Link target="_blank" to={`/order/${order.id}`}>
          Order Summary
        </Link>
      </div>
      {error ? <Message error header="An error occured. Please try again later." /> : null}
      <Button
        negative
        loading={loading}
        onClick={() => removeOrder({ variables: { orderId: order.id } }).then(() => onClose())}
      >
        Remove
      </Button>
    </div>
  );
};

const StatusInformation: FC<{ order: { id: string; status: string } }> = ({ order }) => {
  const [updateStatus, { loading, error }] = useMutation(UPDATE_ORDER);

  const [status, setStatus] = useState<string | any>(order.status);

  const options = [
    ['CREATED', 'CREATED'],
    ['PAID', 'PAID'],
    ['INPROGRESS', 'PREPARING'],
    ['READY', 'READY'],
    ['CANCELLED', 'CANCELLED'],
    ['DELIVERED', 'DELIVERED'],
    ['BILLED', 'BILLED TO ROOM'],
    ['BILLED_TO_SPOT', 'BILLED TO SPOT'],
  ].map(option => ({
    key: option[0],
    text: option[1],
    value: option[0],
  }));

  return (
    <div className={theme.information}>
      <h3>Edit Order Status</h3>
      <div className={theme.field}>
        <p className={theme.label}>STATUS</p>
        <Dropdown
          placeholder="Select Category"
          fluid
          selection
          defaultValue={status}
          options={options}
          onChange={(e, data) => setStatus(data.value)}
        />
      </div>
      {error ? <Message error header="An error occured. Please try again later." /> : null}
      <Button
        positive
        loading={loading}
        onClick={() => updateStatus({ variables: { orderId: order.id, status } })}
      >
        Save
      </Button>
    </div>
  );
};

const FeedbackInformation: FC<{ feedback: { rating: number; comments: string } }> = ({
  feedback,
}) => {
  if (!feedback) {
    return (
      <div className={theme.information}>
        <h3>Customer Feedback</h3>
        <div className={theme.field}>
          <p className={theme.label}>The customer has not provided any feedback yet.</p>
        </div>
      </div>
    );
  }

  return (
    <div className={theme.information}>
      <h3>Customer Feedback</h3>
      <div className={theme.field}>
        <p className={theme.label}>STAR RATING</p>
        <p>{feedback.rating}</p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>COMMENTS</p>
        <p>{feedback.comments}</p>
      </div>
    </div>
  );
};

const OrderDetailModal: FC<{ orderId: string; vendorId: string; onClose: () => void }> = ({
  orderId,
  vendorId,
  onClose,
}) => {
  const { loading, error, data } = useQuery<OrderQueryResponse, OrderQueryVariables>(
    FETCH_ORDER_DETAIL,
    {
      variables: { orderId },
    }
  );

  const sections = data
    ? [
        {
          key: 'information',
          name: 'Details',
          emoji: '📋',
          component: <OrderInformation order={data.order} onClose={onClose} vendorId={vendorId} />,
        },
        {
          key: 'status',
          name: 'Status',
          emoji: '🚦',
          component: <StatusInformation order={data.order} />,
        },
        {
          key: 'feedback',
          name: 'Feedback',
          emoji: '⭐️',
          component: <FeedbackInformation feedback={data.order.feedback} />,
        },
      ]
    : [];

  return (
    <ModalWithSidePanel
      name="ORDER"
      loading={loading}
      error={!!error || !data || !data.order}
      sections={sections}
      onClose={onClose}
    />
  );
};

export default OrderDetailModal;
