import React, { FC, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useParams, useLocation } from 'react-router';
import { Divider } from 'semantic-ui-react';

import AdminLayout from 'layouts/AdminLayout';

import Title from 'components/Title';
import ActionPanel from 'components/ActionPanel';
import ErrorDisplay from 'components/ErrorDisplay';
import Loader from 'components/Loader';

import MenuTable from './MenuTable';
import CreateDishModal from './CreateDishModal';
import UpdateDishModal from './UpdateDishModal';
import VendorDetailModal from './VendorDetailModal';
import OpenCloseVendorSwitch from './OpenCloseVendorSwitch';
import Statistics from './Statistics';

import FETCH_VENDOR from 'graphql/queries/fetchVendor.graphql';
import VendorSettlementReportModal from './VendorSettlementReportModal';

type VendorQueryResponse = {
  vendor: {
    id: string;
    name: string;
    image: string;
    status: string;
    rooms: {
      id: string;
    }[];
    foodCourt: {
      id: string;
    };
    dishes: {
      id: string;
      name: string;
      price: number;
      isOnMenu: boolean;
      category: { name: string };
    }[];
    permissions: {
      canCreateDish: boolean;
      canUpdateDish: boolean;
      canSwitchDishAvailability: boolean;
      canSwitchVendorAvailability: boolean;
    };
  };
};

type VendorQueryVariables = {
  vendorId: string;
};

const Vendor: FC = () => {
  const { vendorId } = useParams<{vendorId}>();
  const location = useLocation();

  const { loading, error, data } = useQuery<VendorQueryResponse, VendorQueryVariables>(
    FETCH_VENDOR,
    {
      variables: {
        vendorId: vendorId ? vendorId : '',
      },
    }
  );

  const [openCreateDishModal, toggleCreateDishModal] = useState<boolean>(false);
  const [openVendorDetailModal, toggleVendorDetailModal] = useState<boolean>(false);
  const [
    openVendorGenerateSettlementReportModal,
    toggleVendorGenerateSettlementReportModal,
  ] = useState<boolean>(false);
  const [dishOnView, setDishOnView] = useState<string>('');

  if (loading) return <Loader type="screen" />;

  if (error || !data || !data.vendor) return <ErrorDisplay type="screen" />;

  const vendor = data.vendor;
  const dishes = vendor.dishes;
  const permissions = data.vendor.permissions;

  function backRoute() {
    // @ts-ignore
    if (location && location.state && location.state.previousState === 'foodcourt' && vendor) {
      return `/foodcourts/${vendor.foodCourt.id}`;
    }
    return '/';
  }

  return (
    <AdminLayout>
      <div>
        <Title name={vendor.name} backButtonRoute={backRoute()} />
        <Divider />
        <h2>QUICK LINKS</h2>
        <ActionPanel>
          {permissions.canCreateDish ? (
            <ActionPanel.Action
              name={'Create Dish'}
              type="callback"
              callback={() => toggleCreateDishModal(true)}
            />
          ) : null}
          {/* <ActionPanel.Action name={'View Menu'} type="callback" callback={() => {}} /> */}
          <ActionPanel.Action name={'View Orders'} type="link" route={`/orders/${vendorId}`} />
          {vendor.rooms && vendor.rooms.length > 0 ? <ActionPanel.Action name={'View Rooms'} type="link" route={`/rooms/${vendorId}`} /> : null}
          <ActionPanel.Action
            name={'Vendor Details'}
            type="callback"
            callback={() => toggleVendorDetailModal(true)}
          />
          <ActionPanel.Action
            name={'Generate Report'}
            type="callback"
            callback={() => toggleVendorGenerateSettlementReportModal(true)}
          />
        </ActionPanel>
        <Statistics vendorId={vendor.id} />
        <Divider />
        <h2>STATUS</h2>
        <OpenCloseVendorSwitch
          vendorId={vendor.id}
          status={vendor.status}
          canSwitchVendorAvailability={permissions.canSwitchVendorAvailability}
        />
        <Divider />
        <h2>MENU</h2>
        <MenuTable
          dishes={dishes}
          onDishSelect={dishId => setDishOnView(dishId)}
          canSwitchDishAvailability={permissions.canSwitchDishAvailability}
        />
        {openCreateDishModal ? (
          <CreateDishModal vendorId={vendor.id} onClose={() => toggleCreateDishModal(false)} />
        ) : null}
        {openVendorDetailModal ? (
          <VendorDetailModal vendorId={vendor.id} onClose={() => toggleVendorDetailModal(false)} />
        ) : null}
        {openVendorGenerateSettlementReportModal ? (
          <VendorSettlementReportModal
            vendorId={vendor.id}
            vendorName={vendor.name}
            onClose={() => toggleVendorGenerateSettlementReportModal(false)}
          />
        ) : null}
        {dishOnView && dishOnView.length > 0 ? (
          <UpdateDishModal
            vendorId={vendor.id}
            dishId={dishOnView}
            onClose={() => setDishOnView('')}
            canUpdateDish={permissions.canUpdateDish}
          />
        ) : null}
      </div>
    </AdminLayout>
  );
};

export default Vendor;
