import React, { FC } from 'react';
import classNames from 'classnames';
import { Button, Table, Responsive } from 'semantic-ui-react';

import { getRoomStatus } from 'utils/getStatus';

import theme from './theme.scss';

type RoomsTableProps = {
  rooms: {
    id: string;
    referenceId: string;
    name: string;
    description: string;
    status: string;
  }[];
  onRoomSelect: (roomId: string) => void;
};

const RoomsTable: FC<RoomsTableProps> = ({ rooms, onRoomSelect }) => {
  return (
    <div className={theme.orderTable}>
      <Button className={classNames(theme.filter, theme.active)}>All Orders</Button>
      {/*
      <Button className={theme.filter}>Today</Button>
      <Button className={theme.filter}>Past Week</Button>
      <Button className={theme.filter}>Upcoming</Button>
      */}
      <Table unstackable compact className={theme.itemTable}>
        <Table.Header className={theme.itemHeader}>
          <Table.Row>
            <Table.HeaderCell width="5">ROOM #</Table.HeaderCell>
            <Responsive as={Table.HeaderCell} width="4" minWidth={992}>
              ROOM NAME
            </Responsive>
            <Table.HeaderCell width="3" minWidth={992}>
              DESCRIPTION
            </Table.HeaderCell>
            <Table.HeaderCell width="4">STATUS</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body className={theme.itemBody}>
          {rooms.map(room => (
            <Table.Row
              key={room.id}
              className={theme.itemRow}
              onClick={() => onRoomSelect(room.id)}
            >
              <Table.Cell width="5">{room.referenceId}</Table.Cell>
              <Responsive as={Table.Cell} width="4" minWidth={992}>
                {room.name}
              </Responsive>
              <Table.Cell width="4" minWidth={992}>
                {room.description}
              </Table.Cell>
              <Table.Cell width="3" className={theme[room.status]}>
                {getRoomStatus(room.status)}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {rooms && rooms.length > 0 ? null : (
        <div className={theme.emptyMessage}>There are no orders to show.</div>
      )}
    </div>
  );
};

export default RoomsTable;
