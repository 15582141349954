import React, { FC } from 'react';
import { useQuery } from 'react-apollo';
import { useParams } from 'react-router';
import { Divider, Table } from 'semantic-ui-react';
import QRCode from 'qrcode.react';
import moment from 'moment';

import DefaultLayout from 'layouts/DefaultLayout';
import ErrorDisplay from 'components/ErrorDisplay';
import Loader from 'components/Loader';

import FETCH_ORDER_BY_REFERENCE from 'graphql/queries/fetchOrderByReference.graphql';

import theme from './theme.scss';

type OrderQueryResponse = {
  order: {
    id: string;
    referenceId: string;
    status: string;
    createdAt: string;
    orderedBy: {
      fullName: string;
    };
    notes: string;
    dishes: {
      id: string;
      name: string;
      quantity: number;
    }[];
    vendor: {
      name: string;
    };
  };
};

type OrderQueryVariables = {
  referenceId: string;
};

const OrderSummary: FC = () => {
  const { orderReferenceId } = useParams();

  const { loading, error, data } = useQuery<OrderQueryResponse, OrderQueryVariables>(
    FETCH_ORDER_BY_REFERENCE,
    {
      variables: {
        referenceId: orderReferenceId ? orderReferenceId : '',
      },
    }
  );

  if (loading) return <Loader type="screen" />;

  if (error || !data || !data.order) return <ErrorDisplay type="screen" />;

  const order = data.order;

  return (
    <DefaultLayout>
      <div className={theme.orderSummary}>
        <div className={theme.content}>
          <h1>
            Your order from <br />
            <span className={theme.organizationName}>
              {order.vendor ? order.vendor.name : ''}
            </span>{' '}
            on <br />
            <span className={theme.visitTime}>
              {moment(order.createdAt).format('DD MMM YYYY')}.
            </span>
          </h1>
          <Divider />
          <h2>Details</h2>
          <div className={theme.information}>
            <div className={theme.field}>
              <p className={theme.label}>ORDER NUMBER</p>
              <p className={theme.value}>{order.referenceId}</p>
            </div>
            <div className={theme.field}>
              <p className={theme.label}>ORDER TIME</p>
              <p className={theme.value}>
                {moment(order.createdAt).format('HH:mm a, DD MMMM YYYY')}
              </p>
            </div>
            <div className={theme.field}>
              <p className={theme.label}>ORDERED FROM</p>
              <p className={theme.value}>{order.vendor ? order.vendor.name : ''}</p>
            </div>
            <div className={theme.field}>
              <p className={theme.label}>STATUS</p>
              <p className={theme.value}>{order.status}</p>
            </div>
            <div className={theme.field}>
              <p className={theme.label}>NAME</p>
              <p className={theme.value}>{order.orderedBy.fullName}</p>
            </div>
            <div className={theme.field}>
              <p className={theme.label}>NOTES</p>
              <p className={theme.value}>{order.notes}</p>
            </div>
          </div>
          <Divider />
          <h2>Purchase</h2>
          <Table unstackable compact className={theme.itemTable}>
            <Table.Header className={theme.itemHeader}>
              <Table.Row>
                <Table.HeaderCell width="10">ITEM NAME</Table.HeaderCell>
                <Table.HeaderCell width="3" textAlign="center">
                  QUANTITY
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body className={theme.itemBody}>
              {order.dishes.map(dish => (
                <Table.Row key={dish.id} className={theme.itemRow}>
                  <Table.Cell width="10">{dish.name}</Table.Cell>
                  <Table.Cell width="3" textAlign="center">
                    {dish.quantity}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
        <div className={theme.qrcode}>
          <QRCode value={order.id} size={256} />
        </div>
      </div>
    </DefaultLayout>
  );
};

export default OrderSummary;
