import React, { FC, useState, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { useParams } from 'react-router';
import { Button, Icon } from 'semantic-ui-react';

import ConsoleLayout from 'layouts/ConsoleLayout';

import ErrorDisplay from 'components/ErrorDisplay';
import QRCodeReader from 'components/QrCodeReader';
import Loader from 'components/Loader';

import OrderList from './OrderList';
import OrderPanel from './OrderPanel';

import FETCH_ORDERS from 'graphql/queries/fetchOrders.graphql';

import orderSound from 'media/incomingorder.WAV';

import theme from './theme.scss';

const audio = new Audio(orderSound);

type OrdersQueryResponse = {
  vendor: {
    id: string;
    name: string;
  };
  ordersByVendor: [
    {
      id: string;
      referenceId: string;
      orderDate: string;
      status: string;
      createdAt: string;
      orderedBy: {
        fullName: string;
      };
      dishes: {
        id: string;
        name: string;
        quantity: number;
      }[];
      dishCount: number;
    }
  ];
};

type OrdersQueryVariables = {
  vendorId: string;
  filter?: string;
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

const VendorConsole: FC = () => {
  const { vendorId } = useParams();

  const { loading, error, data } = useQuery<OrdersQueryResponse, OrdersQueryVariables>(
    FETCH_ORDERS,
    {
      variables: {
        vendorId: vendorId ? vendorId : '',
        filter: 'TODAY',
      },
      pollInterval: 3000,
    }
  );

  const [orderOnDisplay, setOrderOnDisplay] = useState<string>('');
  const [showSidebar, toggleSidebar] = useState<boolean>(false);
  const [orderCount, setOrderCount] = useState<number>(0);

  useEffect(() => {
    if (!data) return;

    const newOrderCount = data.ordersByVendor.filter(order => order.status !== 'CREATED').length;
    if (newOrderCount > orderCount) {
      setOrderCount(newOrderCount);
      if (orderCount !== 0) audio.play();
    }
  }, [data, orderCount]);

  const { width } = useWindowDimensions();

  function sidebarSwitch() {
    if (width > 992) return null;

    if (showSidebar) {
      return (
        <Button
          className={theme.listToggle}
          basic
          icon
          labelPosition="left"
          onClick={() => toggleSidebar(false)}
        >
          <Icon name="chevron left" />
          Close
        </Button>
      );
    }

    return (
      <Button
        className={theme.listToggle}
        icon
        labelPosition="left"
        onClick={() => toggleSidebar(true)}
      >
        <Icon name="list layout" />
        Show Orders
      </Button>
    );
  }

  if (loading) return <Loader type="screen" />;
  if (error || !data) return <ErrorDisplay type="screen" />;

  return (
    <ConsoleLayout>
      {sidebarSwitch()}
      <div className={theme.vendorConsole}>
        {width > 992 || showSidebar ? (
          <OrderList
            orders={data.ordersByVendor}
            selectedOrder={orderOnDisplay}
            onOrderSelect={orderId => {
              setOrderOnDisplay(orderId);
              toggleSidebar(false);
            }}
          />
        ) : null}
        {showSidebar ? null : <OrderPanel orderId={orderOnDisplay} />}
        <QRCodeReader
          onOrderDetect={orderId => {
            setOrderOnDisplay(orderId);
            toggleSidebar(false);
          }}
        />
      </div>
    </ConsoleLayout>
  );
};

export default VendorConsole;
